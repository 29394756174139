import { NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { DateAdapter, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { countriesList, country } from '@features/country/data';
import { getCountryName } from '@features/country/data/utils';
import { Country } from '@features/country/domain';
import { user } from '@features/user/data';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { Subject } from 'rxjs';
import { OrderStatusListModel } from 'src/app/core/domain/order/order-list.model';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetOrderStatusListUseCase } from 'src/app/core/usecases/order/get-order-status-list.usecase';
import { ORDER_STATUSES } from 'src/app/presentation/shared/constants';
import { WEB_ORDERS_REVAMP } from '../../shared/constants/feature-flags';
import { featureAttributeAssign } from '../../shared/utilities/feature-attribute-assign.utility';

@Component({
  selector: 'app-order-filters',
  templateUrl: './order-filters.component.html',
  styleUrls: ['./order-filters.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    MatSelectModule,
    NgFor,
    NgIf,
    MatOptionModule,
    NgStyle,
    TranslateModule,
  ],
})
export class OrderFiltersComponent extends LocalizedComponent implements OnInit {
  public orderSearchFilterForm: UntypedFormGroup;

  public countries: Country[] = countriesList.list;

  public orderStatuses: any;

  public childOrderStatuses: any;

  public orderStatusList: OrderStatusListModel;

  public shouldShowOrdersRevamp = false;

  @Input() filterObject: {
    orderId: string;
    taagerId: string;
    fromDate: string;
    toDate: string;
    country: string;
  };

  @Input() resetTriggered: Subject<boolean>;

  @Input() isChildOrderTabActive: boolean;

  @Input() isEnglishLanguage: boolean;

  @Input() failedOrdersTabSelected: boolean;

  @Output() searchTriggered: EventEmitter<any> = new EventEmitter();

  constructor(
    private dateAdapter: DateAdapter<any>,
    private _getOrderStatusListUseCase: GetOrderStatusListUseCase,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
  ) {
    super();
    this.orderSearchFilterForm = new UntypedFormGroup({
      searchKey: new UntypedFormControl(null),
      status: new UntypedFormControl(null),
      orderId: new UntypedFormControl(null),
      fromDate: new UntypedFormControl(null),
      toDate: new UntypedFormControl(null),
      country: new UntypedFormControl(null),
    });
  }

  ngOnInit(): void {
    this.getOrderRevampStatus();
    this.resetTriggered.subscribe({
      next: () => {
        this.orderSearchFilterForm.reset();
      },
    });
  }

  public getOrderRevampStatus(): void {
    this._getFeatureAttributeUseCase.execute(WEB_ORDERS_REVAMP).subscribe({
      next: (status) => {
        this.shouldShowOrdersRevamp = featureAttributeAssign(status, user.id, country.code);
        this.setupOrderStatuses();
      },
    });
  }

  ngOnChanges(): void {
    if (!this.isEnglishLanguage) {
      this.dateAdapter.setLocale('ar');
    } else {
      this.dateAdapter.setLocale('en');
    }
  }

  onClickSearchButton(): void {
    const { searchKey } = this.orderSearchFilterForm.value;
    if (searchKey) {
      this.orderSearchFilterForm.reset();
      this.orderSearchFilterForm.get('searchKey')!.setValue(searchKey);
    }
    this.searchTriggered.emit(this.orderSearchFilterForm.value);
  }

  setupOrderStatuses(): void {
    if (this.shouldShowOrdersRevamp) {
      this._getOrderStatusListUseCase.execute().subscribe({
        next: (status) => {
          this.orderStatusList = status;
        },
      });
    } else {
      this.orderStatuses = ORDER_STATUSES.ALL_STATUSES.filter((status) =>
        ORDER_STATUSES.ORDERS_FILTER_OPTIONS.includes(status.statusInEnglish),
      );
    }
    this.childOrderStatuses = ORDER_STATUSES.ALL_STATUSES.filter((status) =>
      ORDER_STATUSES.CHILD_ORDERS_FILTER_OPTIONS.includes(status.statusInEnglish),
    );
  }

  onClearFilters(): void {
    this.orderSearchFilterForm.reset();
    this.searchTriggered.emit(this.orderSearchFilterForm.value);
  }

  public getCountryName(isoCode3: string): string {
    return getCountryName(isoCode3);
  }
}
